import { useContext, useMemo, useState } from 'react'

import {
  Button,
  Text,
  theme,
  useHealthToolsCache,
} from '@hhgtech/hhg-components'
import { DatePicker, Radio } from '@hhgtech/hhg-components/mantine'
import { getMarryBabyUrl, redirectToSSOWithReturnUrl } from 'api/utils'
import { DueDateInfoType } from 'components/pages/healthTools/due-date'
import dayjs from 'dayjs'
import { useRouterExtend } from 'hooks/useRouterExtend'
import {
  API_DAYJS_FORMAT,
  HAS_COMMUNITY_SITE,
  HEALTH_TOOL_TYPE,
  QUERY_HEALTH_TOOL,
  basePath,
} from 'interfaces/constants'
import { useIntl } from 'react-intl'
import { AppContext } from 'state/appContext'

import { FormControl, StyledLastPeriodFormContainer } from './index.styled'
import { getDueDateIVF } from './utils'

type Props = {
  onSubmit?: (info: DueDateInfoType, redirectLink?: string) => void
  isEmbed?: boolean
  controlledSubmit?: boolean
}

const DueDateFormIVF = ({
  onSubmit,
  isEmbed = false,
  controlledSubmit,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const { locale, router } = useRouterExtend()
  const {
    state: { isLoggedIn },
  } = useContext(AppContext) || {}
  const [IVFDate, setIVFDate] = useState(new Date())
  const [dayTransfer, setDayTransfer] = useState('3')

  const { getHealthToolLink } = useHealthToolsCache(locale + '_MB')

  const linkTool = useMemo(() => getHealthToolLink(HEALTH_TOOL_TYPE.DUE_DATE), [
    getHealthToolLink,
  ])

  return (
    <StyledLastPeriodFormContainer>
      <FormControl>
        <DatePicker
          label={
            <Text size="xs" type="bold" color={theme.colors.gray400}>
              {f({
                id: 'dueDatePage.ivf.transferDate',
              })}
            </Text>
          }
          value={IVFDate}
          onChange={(value) => {
            setIVFDate(value || new Date())
          }}
          excludeDate={(date) =>
            dayjs(date).isBefore(dayjs().subtract(43, 'week'))
          }
          size="md"
        />
      </FormControl>
      <FormControl>
        <Radio.Group
          name="dayTransfer"
          value={dayTransfer}
          onChange={(val) => setDayTransfer(val)}
          size="md"
          styles={{
            root: {
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            },
          }}
        >
          <Radio
            value="3"
            label={f(
              {
                id: 'dueDatePage.ivf.transferDateRadio',
              },
              {
                number: 3,
              },
            )}
          />
          <Radio
            value="5"
            label={f(
              {
                id: 'dueDatePage.ivf.transferDateRadio',
              },
              {
                number: 5,
              },
            )}
          />
        </Radio.Group>
      </FormControl>
      <FormControl>
        <Button
          style={{ background: '#E55C8A', border: 'none' }}
          isBlock
          className="due-date-calculate-button"
          size="lg"
          color="primary"
          dataEventCategory="Health Tool"
          dataEventAction="Due Date"
          dataEventLabel={`${getMarryBabyUrl(locale)}${basePath}${linkTool}`}
          onClick={() => {
            const currentUrl = isEmbed
              ? `${window.location.origin}${basePath}${getHealthToolLink(
                  HEALTH_TOOL_TYPE.DUE_DATE,
                )}`
              : window.location.origin + window.location.pathname

            const redirectLink =
              currentUrl +
              `?${QUERY_HEALTH_TOOL.type}=2&${
                QUERY_HEALTH_TOOL.startDate
              }=${dayjs(IVFDate).format(API_DAYJS_FORMAT)}&${
                QUERY_HEALTH_TOOL.additional
              }=${dayTransfer}${
                HAS_COMMUNITY_SITE.includes(locale) ? '&joincommunity=1' : ''
              }`

            if (controlledSubmit) {
              onSubmit &&
                onSubmit(
                  getDueDateIVF(IVFDate, Number(dayTransfer)),
                  redirectLink,
                )

              return
            }

            if (!isLoggedIn) {
              redirectToSSOWithReturnUrl(redirectLink, 'due-date')
            } else if (isEmbed) {
              router.push(redirectLink)
            } else {
              onSubmit &&
                onSubmit(
                  getDueDateIVF(IVFDate, Number(dayTransfer)),
                  redirectLink,
                )
            }
          }}
        >
          {f({
            id: 'dueDatePage.lastPeriod.calculate',
          })}
        </Button>
      </FormControl>
    </StyledLastPeriodFormContainer>
  )
}

export { DueDateFormIVF }
