import { Dropdown, Breadcrumbs } from '@hhgtech/hhg-components/components'
import { ThemeProps, MediaQueries } from '@hhgtech/hhg-components/misc'
import styled from 'styled-components'
// import { deviceLayout } from 'styles/breakpoint'

export const StyledHealthTools = styled.div`
  .article-bottom-ad {
    > div {
      max-width: 728px;
      max-height: 90px;
      ${MediaQueries.mbDown} {
        max-width: 320px;
        max-height: 100px;
      }
    }
    margin-bottom: 4rem;
    @supports (margin-bottom: env(safe-area-inset-bottom)) {
      margin-bottom: calc(env(safe-area-inset-bottom) + 4rem);
    }
    ${MediaQueries.mbUp} {
      margin-bottom: 0px;
    }
  }
  .disclaimer {
    padding-bottom: 24px;
  }
`

export const StyledThrHealthTool = styled.div`
  .article-bottom-ad {
    > div {
      max-width: 728px;
      max-height: 90px;
      ${MediaQueries.mbDown} {
        max-width: 320px;
        max-height: 100px;
      }
    }
    margin-bottom: 4rem;
    @supports (margin-bottom: env(safe-area-inset-bottom)) {
      margin-bottom: calc(env(safe-area-inset-bottom) + 4rem);
    }
    ${MediaQueries.mbUp} {
      margin-bottom: 0px;
    }
  }
`
export const StyledMobileSecondArea = styled.div`
  padding-bottom: 40px;

  .featured-topics {
    padding: 0;
    & > :first-child {
      padding: 0;
    }
  }
`

export const StyledSuggestedArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 80px;

  .featured-topics {
    padding: 0;
  }

  article[data-size='md'][data-direction='horizontal'] .banner {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
  }

  .top-ad {
    iframe {
      margin-top: 20px;
    }
    ${MediaQueries.mbUp} {
      max-width: 728px;
      max-height: 300px;
    }
    ${MediaQueries.mbDown} {
      max-width: 336px;
    }
  }
  .bottom-ad {
    iframe {
      margin-top: 20px;
    }
  }

  &.baby-growth {
    padding-bottom: 40px;
  }

  .article-container,
  .feature-small-article {
    padding-left: 0;
    padding-top: 0;
  }
`

export const StyledArticleSeeMore = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  &.baby-growth {
    margin-bottom: 0;
  }
`
export const StyledMyHealthBanner = styled.div`
  width: 100%;

  ${MediaQueries.mbUp} {
    border-bottom: 8px solid
      ${(props: ThemeProps) => props.theme.colors.neutral50};
  }
`
export const StyledMyHealthBannerRecord = styled.div`
  width: 100%;
  margin-top: 32px;

  ${MediaQueries.mbUp} {
    border-bottom: 8px solid
      ${(props: ThemeProps) => props.theme.colors.neutral50};
  } ;
`

export const StyledRecordsTimeRange = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding-left: 28px;

  .input-container {
    width: unset;
    .pickerOverlayWrapper {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .date-range-picker {
    width: fit-content;
    margin: 0 auto;
    padding: 6px 8px;
    min-width: 230px;
    text-align: center;
    font-size: 14px;

    @media screen and (max-width: 425px) {
      min-width: unset;
      width: 100%;
      text-align: left;
    }
  }
`

export const StyledDropdown = styled(Dropdown)`
  min-width: 154px;
  background-position: right 8px center;

  &#chart-time-type {
    min-width: 112px;
    margin-left: 16px;
  }

  > div {
    padding: 6px 8px;
  }

  > ul {
    z-index: 1000;
  }
`

export const StyledHealthToolBreadcrumbs = styled(Breadcrumbs)`
  padding: 1rem 0 1rem;
`

export const StyledImageIcon = styled.img`
  img& {
    width: 16px;
    height: 16px;
  }
`

export const StyledHealthtoolEmbeddedCareWrapper = styled.div`
  padding: 0 16px;

  .container {
    width: calc(100% + 32px);
    margin: 0 -16px;
  }

  .grid-layout > div:first-child {
    margin-left: 8px;
  }
`

export const Separator = styled.div`
  width: 1px;
  background: #e4e8ec;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 8px;
`
export const StyledHealthToolsPage = styled.div`
  .breadcrumbs {
    padding: 8px 0px 16px 0;

    ${MediaQueries.mbUp} {
      padding-top: 20px;
      padding-left: 14px;
    }
  }
  border-bottom: 1px solid #e4e8ec;
`
