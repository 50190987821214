import { DueDateInfoType } from 'components/pages/healthTools/due-date'
import dayjs from 'dayjs'
import { API_DAYJS_FORMAT } from 'interfaces/constants'

import { DueDateCalculatorType } from '.'
export const getDueDateLastPeriod = (
  lastPeriodDate: Date,
  periodLength: number,
): DueDateInfoType => {
  const pregnancyLength = 272 + (periodLength - 21)
  const dueDate = new Date(lastPeriodDate)
  dueDate.setDate(dueDate.getDate() + pregnancyLength)
  return {
    startDate: lastPeriodDate,
    dueDate,
    otherInfo: {
      method: DueDateCalculatorType.lastPeriod + 1, // zero index -> 1 based index
      first_day: dayjs(lastPeriodDate).format(API_DAYJS_FORMAT),
      cycle_length_days: periodLength,
      conception_date: null,
      ivf_transfer_date: null,
      days_transfer: null,
    },
  }
}

export const getDueDateConception = (conceptionDate: Date): DueDateInfoType => {
  const dueDate = new Date(conceptionDate)
  dueDate.setDate(dueDate.getDate() + 265)
  return {
    startDate: conceptionDate,
    dueDate,
    otherInfo: {
      method: DueDateCalculatorType.conception + 1, // zero index -> 1 based index
      conception_date: dayjs(conceptionDate).format(API_DAYJS_FORMAT),
      first_day: null,
      cycle_length_days: null,
      ivf_transfer_date: null,
      days_transfer: null,
    },
  }
}

export const getDueDateIVF = (
  IVFDate: Date,
  numDayTransfer: number,
): DueDateInfoType => {
  const dueDate = new Date(IVFDate)
  dueDate.setDate(
    dueDate.getDate() + 265 - numDayTransfer,
    // (numDayTransfer === 3 ? 262 : numDayTransfer === 5 ? 260 : 265),
  )
  return {
    startDate: IVFDate,
    dueDate,
    otherInfo: {
      method: DueDateCalculatorType.IVF + 1, // zero index -> 1 based index
      ivf_transfer_date: dayjs(IVFDate).format(API_DAYJS_FORMAT),
      days_transfer: numDayTransfer,
      conception_date: null,
      first_day: null,
      cycle_length_days: null,
    },
  }
}

export const DateToInputValue = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`
}

export const getDaysDifference = (firstDate: Date, secondDate: Date) => {
  const diffTime = Math.abs(secondDate.getTime() - firstDate.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}
