import { useMemo, useState } from 'react'

import {
  Button,
  Text,
  theme,
  useHealthToolsCache,
} from '@hhgtech/hhg-components'
import { DatePicker } from '@hhgtech/hhg-components/mantine'
import { getMarryBabyUrl, redirectToSSOWithReturnUrl } from 'api/utils'
import { DueDateInfoType } from 'components/pages/healthTools/due-date'
import dayjs from 'dayjs'
import { useAppContext } from 'hooks/useAppContext'
import { useRouterExtend } from 'hooks/useRouterExtend'
import {
  API_DAYJS_FORMAT,
  HAS_COMMUNITY_SITE,
  HEALTH_TOOL_TYPE,
  QUERY_HEALTH_TOOL,
  basePath,
} from 'interfaces/constants'
import { useIntl } from 'react-intl'

import { FormControl, StyledLastPeriodFormContainer } from './index.styled'
import { getDueDateConception } from './utils'

type Props = {
  onSubmit?: (info: DueDateInfoType, redirectLink?: string) => void
  isEmbed?: boolean
  controlledSubmit?: boolean
}

const DueDateFormConception = ({
  onSubmit,
  isEmbed = false,
  controlledSubmit,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const { locale, router } = useRouterExtend()
  const {
    state: { isLoggedIn },
  } = useAppContext()
  const [conceptionDate, setConceptionDate] = useState(new Date())

  const { getHealthToolLink } = useHealthToolsCache(locale + '_MB')

  const linkTool = useMemo(() => getHealthToolLink(HEALTH_TOOL_TYPE.DUE_DATE), [
    getHealthToolLink,
  ])

  return (
    <StyledLastPeriodFormContainer>
      <FormControl>
        <DatePicker
          label={
            <Text size="xs" type="bold" color={theme.colors.gray400}>
              {f({
                id: 'dueDatePage.conceptionDate',
              })}
            </Text>
          }
          value={conceptionDate}
          size="md"
          onChange={(value) => {
            setConceptionDate(value || new Date())
          }}
          excludeDate={(date) =>
            dayjs(date).isBefore(dayjs().subtract(43, 'week'))
          }
        />
      </FormControl>
      <FormControl>
        <Button
          style={{ background: '#E55C8A', border: 'none' }}
          isBlock
          className="due-date-calculate-button"
          size="lg"
          color="primary"
          dataEventCategory="Health Tool"
          dataEventAction="Due Date"
          dataEventLabel={`${getMarryBabyUrl(locale)}${basePath}${linkTool}`}
          onClick={() => {
            const currentUrl = isEmbed
              ? `${window.location.origin}${basePath}${getHealthToolLink(
                  HEALTH_TOOL_TYPE.DUE_DATE,
                )}`
              : window.location.origin + window.location.pathname

            const redirectLink =
              currentUrl +
              `?${QUERY_HEALTH_TOOL.type}=1&sd=${dayjs(conceptionDate).format(
                API_DAYJS_FORMAT,
              )}${
                HAS_COMMUNITY_SITE.includes(locale) ? '&joincommunity=1' : ''
              }&${QUERY_HEALTH_TOOL.additional}= `

            if (controlledSubmit) {
              onSubmit &&
                onSubmit(getDueDateConception(conceptionDate), redirectLink)

              return
            }

            if (!isLoggedIn) {
              redirectToSSOWithReturnUrl(redirectLink, 'due-date')
            } else if (isEmbed) {
              router.push(redirectLink)
            } else {
              onSubmit &&
                onSubmit(getDueDateConception(conceptionDate), redirectLink)
            }
          }}
        >
          {f({
            id: 'dueDatePage.lastPeriod.calculate',
          })}
        </Button>
      </FormControl>
    </StyledLastPeriodFormContainer>
  )
}

export { DueDateFormConception }
