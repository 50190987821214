import { useMemo, useState } from 'react'

import {
  Button,
  Text,
  theme,
  useHealthToolsCache,
} from '@hhgtech/hhg-components'
import { DatePicker, Select } from '@hhgtech/hhg-components/mantine'
import { getMarryBabyUrl, redirectToSSOWithReturnUrl } from 'api/utils'
import { DueDateInfoType } from 'components/pages/healthTools/due-date'
import dayjs from 'dayjs'
import { useAppContext } from 'hooks/useAppContext'
import { useRouterExtend } from 'hooks/useRouterExtend'
import {
  API_DAYJS_FORMAT,
  HAS_COMMUNITY_SITE,
  HEALTH_TOOL_TYPE,
  QUERY_HEALTH_TOOL,
  basePath,
} from 'interfaces/constants'
import { useIntl } from 'react-intl'

import { IBtnProp } from '.'
import { FormControl, StyledLastPeriodFormContainer } from './index.styled'
import { getDueDateLastPeriod } from './utils'

type Props = {
  onSubmit?: (info: DueDateInfoType, redirectLink?: string) => void
  isEmbed?: boolean
  controlledSubmit?: boolean
  btnProp?: IBtnProp
}

const DueDateFormLastPeriod = ({
  onSubmit,
  isEmbed = false,
  controlledSubmit,
  btnProp,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const {
    state: { isLoggedIn },
  } = useAppContext()
  const [periodLength, setPeriodLength] = useState('28')
  const { locale, router } = useRouterExtend()
  const { getHealthToolLink } = useHealthToolsCache(locale + '_MB')

  const linkTool = useMemo(() => getHealthToolLink(HEALTH_TOOL_TYPE.DUE_DATE), [
    getHealthToolLink,
  ])

  const periodLengthDropDownOptions: {
    label: string
    value: string
  }[] = useMemo(
    () =>
      [...Array(21)].map((_, index) => ({
        label: f({ id: 'dueDatePage.daysText' }, { number: index + 20 }),
        value: `${index + 20}`,
      })),
    [],
  )
  const [lastPeriodDate, setLastPeriodDate] = useState(new Date())

  return (
    <StyledLastPeriodFormContainer>
      <FormControl>
        <DatePicker
          label={
            <Text size="xs" type="bold" color={theme.colors.gray400}>
              {f({
                id: 'dueDatePage.lastPeriod.lastPeriodLabel',
              })}
            </Text>
          }
          value={lastPeriodDate}
          onChange={(value) => {
            setLastPeriodDate(value || new Date())
          }}
          excludeDate={(date) =>
            dayjs(date).isBefore(dayjs().subtract(43, 'week'))
          }
          size="md"
        />
      </FormControl>
      <FormControl>
        <Select
          label={
            <Text size="xs" type="bold" color={theme.colors.gray400}>
              {f({
                id: 'dueDatePage.lastPeriod.periodLengthLabel',
              })}
            </Text>
          }
          size="md"
          maxDropdownHeight={300}
          data={periodLengthDropDownOptions}
          value={periodLength}
          onChange={(value: string) => setPeriodLength(value || '')}
        />
      </FormControl>
      <FormControl>
        <Button
          {...btnProp}
          style={{ background: '#E55C8A', border: 'none' }}
          isBlock
          dataEventCategory="Health Tool"
          dataEventAction="Due Date"
          dataEventLabel={`${getMarryBabyUrl(locale)}${basePath}${linkTool}`}
          className="due-date-calculate-button"
          size="lg"
          color="primary"
          onClick={() => {
            const currentUrl = isEmbed
              ? `${window.location.origin}${basePath}${getHealthToolLink(
                  HEALTH_TOOL_TYPE.DUE_DATE,
                )}`
              : window.location.origin + window.location.pathname

            const redirectLink =
              currentUrl +
              `?${QUERY_HEALTH_TOOL.type}=0&${
                QUERY_HEALTH_TOOL.startDate
              }=${dayjs(lastPeriodDate).format(API_DAYJS_FORMAT)}&${
                QUERY_HEALTH_TOOL.additional
              }=${periodLength}${
                HAS_COMMUNITY_SITE.includes(locale) ? '&joincommunity=1' : ''
              }`

            if (controlledSubmit) {
              onSubmit &&
                onSubmit(
                  getDueDateLastPeriod(lastPeriodDate, Number(periodLength)),
                  redirectLink,
                )

              return
            }

            if (!isLoggedIn) {
              redirectToSSOWithReturnUrl(redirectLink, 'due-date')
            } else if (isEmbed) {
              router.push(redirectLink)
            } else {
              onSubmit &&
                onSubmit(
                  getDueDateLastPeriod(lastPeriodDate, Number(periodLength)),
                  redirectLink,
                )
            }
          }}
        >
          {f({
            id: 'dueDatePage.lastPeriod.calculate',
          })}
        </Button>
      </FormControl>
    </StyledLastPeriodFormContainer>
  )
}

export { DueDateFormLastPeriod }
