import { useEffect, useState } from 'react'

import { Text } from '@hhgtech/hhg-components'
import { ExpandedCard } from 'components/molecules/expandedCard'
import { assetImagePath } from 'interfaces/constants'
import { useIntl } from 'react-intl'

import { StyledDuoDateImageIcon } from './index.styled'

export type Props = {
  dueDateInfos?: {
    title: string
    description: string
  }[]
  defaultExpandIndex?: number
  calculatorType?: number
}

const DueDateInfo = ({
  dueDateInfos = [],
  defaultExpandIndex,
  calculatorType,
}: Props) => {
  const { formatMessage: f } = useIntl()

  const [openInfo, setOpenInfo] = useState(defaultExpandIndex)

  // const dueDateInfos = [
  //   {
  //     title: f({ id: 'dueDatePage.lastPeriod' }),
  //     description: f({ id: 'dueDatePage.lastPeriodExplaination' }),
  //   },
  //   {
  //     title: f({ id: 'dueDatePage.conceptionDate' }),
  //     description: f({ id: 'dueDatePage.conceptionDateExplaination' }),
  //   },
  //   {
  //     title: f({ id: 'dueDatePage.ivf' }),
  //     description: f({ id: 'dueDatePage.ivfExplaination' }),
  //   },
  // ]
  useEffect(() => {
    setOpenInfo(-1)
  }, [])
  useEffect(() => {
    setOpenInfo(calculatorType)
  }, [calculatorType])

  return (
    <div id="calculator-info">
      <Text
        size="label1"
        type="bold"
        color="#8C8C8C"
        style={{
          margin: '16px 0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledDuoDateImageIcon src={`${assetImagePath}/info-icon.svg`} />
        {f({ id: 'dueDatePage.info' })}
      </Text>
      {dueDateInfos?.map((info, index) => (
        <ExpandedCard
          key={index}
          title={info.title}
          isExpand={openInfo === index}
          // onChange={(value: boolean) =>
          //   value ? setOpenInfo(index) : setOpenInfo(-1)
          // }
          content={
            <Text size="p2" style={{ lineHeight: '1.5' }}>
              <div dangerouslySetInnerHTML={{ __html: info.description }} />
            </Text>
          }
          iconPosition="right"
          style={{
            marginBottom: '1rem',
          }}
        />
      ))}
    </div>
  )
}

export { DueDateInfo }
