import { ReactNode, useState, useRef, useEffect } from 'react'

import { domainLocales } from '@hhgtech/hhg-components/constants'
import { Text } from '@hhgtech/hhg-components/mantine'
import { useRouterExtend } from 'hooks/useRouterExtend'
import MinusIcon from 'public/svg/minus.svg'
import PlusIcon from 'public/svg/plus.svg'
import { CSSProperties } from 'styled-components'

import {
  StyledHeading,
  StyledExpandableContainer,
  StyledContentWrapper,
  StyledExpandedCard,
} from './index.styled'

export type Props = {
  title: ReactNode
  contentString?: string
  content?: ReactNode
  isMobile?: boolean
  defaultExpand?: boolean
  className?: string
  iconPosition?: 'left' | 'right'
  style?: CSSProperties
  isExpand?: boolean
  onChange?: any
  customIcon?: {
    min: JSX.Element
    max: JSX.Element
  }
  iconType?: 'arrow' | 'default'
}
const ExpandedCard = ({
  title,
  content,
  contentString,
  isMobile,
  iconPosition,
  className,
  style,
  defaultExpand,
  isExpand,
  onChange,
  customIcon,
}: Props) => {
  const { locale, router } = useRouterExtend()
  const [expand, setExpand] = useState(isExpand || false)
  const [cHeight, setCHeight] = useState(0)
  const ExpandedCardRef = useRef<HTMLDivElement>(null)
  const { asPath } = router

  useEffect(() => {
    if (defaultExpand) {
      setTimeout(() => {
        setExpand(defaultExpand)
      }, 100)
    }
  }, [])

  useEffect(() => {
    setExpand(!!isExpand)
    setCHeight(ExpandedCardRef.current?.clientHeight || 0)
  }, [isExpand])

  return (
    <StyledExpandedCard className={className} style={style}>
      <StyledHeading
        onClick={() => {
          if (!!onChange) {
            onChange(!expand)
          } else {
            setExpand((e) => !e)
          }
        }}
        data-event-category="Expand Info"
        data-event-action="Click"
        data-event-label={`https://${domainLocales[locale]}${asPath}`}
      >
        <div
          style={{
            position: 'absolute',
            right: iconPosition === 'right' ? 0 : 'unset',
            left: iconPosition === 'left' ? 0 : 'unset',
            display: 'flex',
          }}
        >
          {expand
            ? customIcon?.min || (
                <MinusIcon width={isMobile ? '16' : '20'} viewBox="0 0 16 3" />
              )
            : customIcon?.max || (
                <PlusIcon width={isMobile ? '16' : '20'} viewBox="0 0 16 16" />
              )}
        </div>
        <div
          style={{
            flex: 1,
            paddingRight: iconPosition === 'right' ? '16px' : '0',
          }}
        >
          {typeof title === 'string' ? (
            <Text size="p2" weight={'bold'}>
              {title}
            </Text>
          ) : (
            title
          )}
        </div>
      </StyledHeading>
      <StyledExpandableContainer
        data-expand={expand}
        style={
          expand
            ? {
                height: cHeight + 8,
              }
            : {}
        }
      >
        {contentString ? (
          <StyledContentWrapper ref={ExpandedCardRef}>
            <Text size="p3" as="div">
              <div dangerouslySetInnerHTML={{ __html: contentString }} />
            </Text>
          </StyledContentWrapper>
        ) : null}
        {!contentString && content ? (
          <StyledContentWrapper ref={ExpandedCardRef}>
            <Text size="p3" as="div">
              {content}
            </Text>
          </StyledContentWrapper>
        ) : null}
      </StyledExpandableContainer>
    </StyledExpandedCard>
  )
}

export { ExpandedCard }
