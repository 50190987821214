import { CategoryCard } from '@hhgtech/hhg-components/components'
import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components/misc'
import { StyledImageIcon } from 'components/pages/healthTools/index.styled'
import styled from 'styled-components'

export const StyledDueDateCalculator = styled.div`
  padding: 16px 24px 24px;
  border-radius: 16px;
  background: #f2fcf7;
`

export const StyledMethodContainer = styled.div`
  width: 100%;
  .method-label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

export const StyledMethodSwitcherContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 24px;
`

export const StyledMethodCardWrapper = styled.div`
  width: 31%;
  ${MediaQueries.mbDown} {
    width: 32.5%;
  }
`
export const StyledCategoryCard = styled(CategoryCard)`
  width: 100%;
  justify-content: space-around;
  padding: 16px 0px 12px;
  opacity: 0.6;
  border-radius: 16px;

  .banner {
    height: 40px;
    background: white !important;
  }
  .content > p {
    font-size: 12px;
    line-height: 20px;
    color: ${(props: ThemeProps) => props.theme.mbColors.gray};
  }
  ${MediaQueries.mbDown} {
    padding: 0.25rem;
  }
  &.active {
    border-color: ${(props: ThemeProps) => props.theme.mbColors.mint};
    box-shadow: none;
    opacity: 1;
  }
`

export const StyledLastPeriodFormContainer = styled(StyledMethodContainer)`
  #due-date-dropdown {
    z-index: 1;
    width: 100%;
    min-width: unset;
    max-width: unset;
    margin-bottom: 2rem;
    color: ${(props: ThemeProps) => props.theme.colors.gray800};
  }
  .due-date-input {
    /* z-index: 11; */
    width: 100%;
    min-width: unset;
    max-width: unset;
    color: ${(props: ThemeProps) => props.theme.colors.gray800};
  }
  .due-date-calculate-button {
    margin-right: auto;
    margin-left: auto;
  }
`

export const StyledScrollLinkContainer = styled.div`
  display: flex;
  align-items: right;

  & > p {
    display: inline-block;
    border-bottom: solid 1px
      ${(props: ThemeProps) => props.theme.colors.primaryBase};
    margin-bottom: 24px;
    margin-left: auto;
    color: ${(props: ThemeProps) => props.theme.colors.primaryBase};
    cursor: pointer;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
  }
`

export const StyledDuoDateImageIcon = styled(StyledImageIcon)`
  margin-right: 4px;
`

export const FormControl = styled.div`
  margin-bottom: 1rem;
`
