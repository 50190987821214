import { useCallback, useState } from 'react'

import { Text, theme } from '@hhgtech/hhg-components'
import { AdBlock } from 'components/molecules/adBlock'
import { DueDateInfoType } from 'components/pages/healthTools/due-date'
import { assetImagePath } from 'interfaces/constants'
import { useIntl } from 'react-intl'
import { IResultDataTrack } from 'utils/tracking'

import { DueDateFormConception } from './dueDateFormConception'
import { DueDateFormIVF } from './dueDateFormIVF'
import { DueDateFormLastPeriod } from './dueDateFormLastPeriod'
import { DueDateInfo } from './dueDateInfo'
import {
  StyledCategoryCard,
  StyledDueDateCalculator,
  StyledMethodCardWrapper,
  StyledMethodContainer,
  StyledMethodSwitcherContainer,
  StyledScrollLinkContainer,
} from './index.styled'

export enum DueDateCalculatorType {
  lastPeriod = 0,
  conception = 1,
  IVF = 2,
}
export type IBtnProp = IResultDataTrack

export type Props = {
  onSubmitDueDate?: (info: DueDateInfoType, redirectLink?: string) => void
  isEmbed?: boolean
  dueDateInfo?: [
    {
      title: string
      description: string
    },
  ]
  adUnitPath?: string
  controlledSubmit?: boolean
  btnProp?: IBtnProp
}
const DueDateCalculator = ({
  onSubmitDueDate,
  isEmbed = false,
  dueDateInfo,
  controlledSubmit,
  adUnitPath,
  btnProp,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const [calculatorType, setCalculatorType] = useState<DueDateCalculatorType>(
    DueDateCalculatorType.lastPeriod,
  )
  const calculatingMethods = [
    {
      imageSrc: `${assetImagePath}/due-date-last-period-icon.png`,
      label: f({ id: 'dueDatePage.lastPeriod' }),
    },
    {
      imageSrc: `${assetImagePath}/due-date-conception-date-icon.png`,
      label: f({ id: 'dueDatePage.conceptionDate' }),
    },
    {
      imageSrc: `${assetImagePath}/due-date-ivf-icon.png`,
      label: f({ id: 'dueDatePage.ivf' }),
    },
  ]

  const scrollIntoExplaination = useCallback(() => {
    const info = document.querySelector('#calculator-info')
    if (info) {
      const top = info.getBoundingClientRect().top + window.scrollY - 80
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    }
  }, [])

  return (
    <>
      <StyledDueDateCalculator>
        <StyledMethodContainer>
          <div className="method-label">
            <Text as="span" size="s3" type="bold" color={theme.colors.gray600}>
              {f({ id: 'dueDatePage.calculationMethod' })}
            </Text>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={scrollIntoExplaination}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 12.75C8.5875 12.75 8.25 12.4125 8.25 12V9C8.25 8.5875 8.5875 8.25 9 8.25C9.4125 8.25 9.75 8.5875 9.75 9V12C9.75 12.4125 9.4125 12.75 9 12.75ZM8.25 6.75H9.75V5.25H8.25V6.75Z"
                fill="#2D87F3"
              />
            </svg>
          </div>

          <StyledMethodSwitcherContainer>
            {calculatingMethods.map((method, index) => (
              <StyledMethodCardWrapper
                key={method.label}
                onClick={(e) => {
                  e.stopPropagation()
                  setCalculatorType(index)
                }}
              >
                <StyledCategoryCard
                  className={index === calculatorType ? 'active' : undefined}
                  imgBanner={
                    <img
                      loading="lazy"
                      src={method.imageSrc}
                      style={{
                        width: '40px',
                        height: '40px',
                        objectFit: 'contain',
                        maxWidth: '100%',
                      }}
                      alt={method.label}
                    />
                  }
                  label={method.label}
                />
              </StyledMethodCardWrapper>
            ))}
          </StyledMethodSwitcherContainer>
          {!isEmbed && <StyledScrollLinkContainer />}
        </StyledMethodContainer>

        {calculatorType === DueDateCalculatorType.lastPeriod ? (
          <DueDateFormLastPeriod
            isEmbed={isEmbed}
            controlledSubmit={controlledSubmit}
            onSubmit={onSubmitDueDate}
            btnProp={btnProp}
          />
        ) : null}
        {calculatorType === DueDateCalculatorType.conception ? (
          <DueDateFormConception
            isEmbed={isEmbed}
            controlledSubmit={controlledSubmit}
            onSubmit={onSubmitDueDate}
          />
        ) : null}
        {calculatorType === DueDateCalculatorType.IVF ? (
          <DueDateFormIVF
            isEmbed={isEmbed}
            controlledSubmit={controlledSubmit}
            onSubmit={onSubmitDueDate}
          />
        ) : null}
        {adUnitPath && (
          <AdBlock
            adUnitPath={adUnitPath}
            size={[300, 250]}
            divId={'div-gpt-ad-topbanner-mobile'}
            pos={['TopBanner']}
          />
        )}
      </StyledDueDateCalculator>

      {!isEmbed && (
        <DueDateInfo
          calculatorType={calculatorType}
          key={calculatorType}
          defaultExpandIndex={calculatorType}
          dueDateInfos={dueDateInfo}
        />
      )}
    </>
  )
}

export { DueDateCalculator }
