import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components/misc'
import styled from 'styled-components'

export const StyledExpandedCard = styled.div`
  display: block;
`
export const StyledHeading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 0.5rem;
  cursor: pointer;
`
export const StyledContentWrapper = styled.div`
  padding: 12px;
  background: ${(props: ThemeProps) => props.theme.colors.neutral50};
  border-radius: 6px;
  ${MediaQueries.mbUp} {
    padding: 1rem;
  }

  * {
    /* max-width: 100%; */
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
  }
`

export const StyledExpandableContainer = styled.div`
  overflow: hidden;
  height: 0;
  transition: height 0.4s ease;
  margin-top: 8px;
  overflow-wrap: anywhere;

  &[data-expand='true'] {
    margin-bottom: 0.5rem;
    ${MediaQueries.mbUp} {
      margin-bottom: 1rem;
    }
  }
`
